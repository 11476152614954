<template>
  <div>
    <section class="goal">
      <h2>テキスト取得</h2>
      <div class="wrap">
        <input v-model="text" />
        <pre>{{ text }}</pre>
      </div>
    </section>
    <hr class="border" />
    <p class="border_txt">これ以下は模写不要</p>
    <section class="question">
      <h2>問題</h2>
      <div class="wrap">
        <p class="question_txt">以上のアプリケーションを作成しなさい</p>
      </div>
    </section>
    <section class="answer">
      <h2>回答</h2>
      <div v-if="showAnswer">
        <button class="answer_btn" @click="showAnswer = false">
          回答を非表示
        </button>
        <div class="wrap">
          <pre>
            <code v-pre>
&lt;template&gt;
  &lt;div&gt;
    &lt;input v-model="text" /&gt;
    &lt;pre&gt;{{ text }}&lt;/pre&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
export default {
  data() {
    return {
      text: "",
    };
  },
};
&lt;/script&gt;
            </code>
          </pre>
        </div>
      </div>
      <button v-else class="answer_btn" @click="showAnswer = true">
        回答を表示
      </button>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: "",
      showAnswer: false,
    };
  },
};
</script>
